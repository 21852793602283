<template>
	<CommonPage>
		<div class="index">
			<Wrapper color="#121212">
				<div class="top">
					<h1>{{ domainConfig['IAmTitle'] }}</h1>
					<div class="date">
						{{ `${month} | ${day}` }}
					</div>
				</div>
			</Wrapper>
			<component
				:is="contentComponent"
				:curOne="curOne" />
			<Wrapper color="#ffffff">
				<div class="b">
					<div class="son">
						<Ad :ads="adsensConfig.ad1"></Ad>

						<div class="text">Related Content</div>
						<div class="hs">
							<div
								@click="$router.push({ name: 'detail', query: Object.keys($store.state.query).length === 0 ? undefined : $store.state.query, params: { id: item?.id, title: item.title.toLocaleLowerCase().replaceAll(' ', '-') } })"
								v-for="item in list"
								:key="item?.id"
								class="itemBlock pointer"
								:style="{ background: `url(${item?.thumbnailImgUrl}) center center / cover no-repeat, linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0))` }">
								<div class="e"></div>
								<div class="num">{{ item?.category }}</div>
								<div class="title">
									{{ item?.title }}
								</div>
							</div>
							<Ad
								:ads="adsensConfig.ad2"
								:posi="{
									pc: {
										colNum: 3,
										rowAt: 4,
									},
									m: 3,
								}"></Ad>
							<Ad
								:ads="adsensConfig.ad3"
								:posi="{
									m: 7,
								}"></Ad>
						</div>
						<div
							class="view-all-btn"
							@click="$router.push({ name: 'all-list', query: Object.keys($store.state.query).length === 0 ? undefined : $store.state.query })">
							View All
						</div>
					</div>
				</div>
			</Wrapper>
		</div>
	</CommonPage>
</template>
<script>
import { getList } from '../api'
const _m = () => import('@/pages/components/bannerM.vue') //代码切割
const _pc = () => import('@/pages/components/bannerPC.vue') //代码切割
import '@/css/index.scss'
export default {
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{
					name: 'description',
					content: this.description,
				},
				{
					name: 'title',
					content: this.title,
				},
				{
					property: 'og:title',
					content: this.title,
				},
				{
					property: 'og:description',
					content: this.description,
				},
			],
		}
	},
	data() {
		return {
			month: '',
			day: '',
			contentComponent: null,
			posi: '',
			quoteEng: '',
			curOne: {},
			list: [],
		}
	},
	computed: {
		title() {
			return `Home | ${this.domainConfig['IAmTitle']}`
		},
		description() {
			return this.domainConfig.description
		},
	},
	async activated() {
		// await this.loadAdSenseScript() // 重新加载广告脚本
	},
	created() {
		if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
			this.contentComponent = _pc
			return
		}
		this.contentComponent = _m
	},
	async beforeMount() {
		const res = await getList({
			type: this.domainConfig['type'],
			pageNum: 1,
			pageSize: 30,
		})
		console.log('🚀🚀🚀 res: ', res)
		let list = res.popularizations
		list.map((item) => {
			return {
				id: item.id,
				title: item.title,
				type: item.type,
				url: item.thumbnailImgUrl || this.getBlankImg(),
				category: item.category,
				summary: item.summary,
			}
		})
		this.list = list
		// this.$store.state.list = list
		this.curOne = list[0]

		const today = new Date()
		// 定义月份名称数组
		const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

		// 获取月份和日期
		const month = monthNames[today.getMonth()]
		const day = today.getDate()
		this.month = month
		this.day = day
	},
	async mounted() {
		if (typeof window !== 'undefined') {
			this.initAdTracking() //客户端交互
		}
	},
	methods: {
		//客户端交互 1121已优化版本
		initAdTracking() {
			this.activeIframe = null // 存储最近被点击的 iframe
			this.iframes = new Map() // 存储已添加的 iframe

			// 监听 iframe 的插入
			this.watchForIframeInsertion()

			// 设置页面可见性变化的处理
			this.setupVisibilityTracking()
		},
		watchForIframeInsertion() {
			const observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					mutation.addedNodes.forEach((node) => {
						if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
							this.addIframe(node)
						}
					})
				})
			})

			observer.observe(document.body, { childList: true, subtree: true })
		},
		addIframe(iframeElement) {
			if (this.iframes.has(iframeElement)) {
				return // 已经添加过，直接返回
			}

			this.iframes.set(iframeElement, {
				hasTracked: false,
				adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
			})

			// 如果还没有启动定时器，启动检查点击的定时器
			if (!this.checkInterval) {
				this.checkInterval = setInterval(this.checkIframeClick, 200)
			}
		},
		checkIframeClick() {
			const activeElement = document.activeElement
			if (activeElement && this.iframes.has(activeElement)) {
				const iframeData = this.iframes.get(activeElement)
				if (!iframeData.hasTracked) {
					this.handleIframeClick(activeElement)
					iframeData.hasTracked = true
					this.activeIframe = { element: activeElement, ...iframeData }
				}
			}
		},
		handleIframeClick(iframeElement) {
			console.log('Iframe 点击触发了!', iframeElement)
			const adContainer = iframeElement.closest('ins.adsbygoogle')

			if (adContainer) {
				// 获取父级 ins 元素的边界矩形
				const insRect = adContainer.getBoundingClientRect()

				// 计算父级 ins 元素相对于页面左上角的位置和高度
				const transformData = {
					insX: insRect.left,
					insY: insRect.top,
					insHeight: insRect.height,
					windowWidth: window.innerWidth,
					windowHeight: window.innerHeight,
				}

				// 调用核心功能
				this.$adClick(JSON.stringify(transformData))
				this.trackEvent('ad_iframe_click', 'click', {
					'data-ad-slot': adContainer.dataset.adSlot,
				})
			}
		},
		setupVisibilityTracking() {
			this.visibilityChangeHandler = () => {
				if (document.visibilityState === 'visible') {
					console.log('页面激活状态，返回页面')
					this.activeIframe = null // 重置最近被点击的 iframe 记录
				} else {
					console.log('页面非激活状态')
					if (this.activeIframe) {
						// 上报逻辑
						this.trackEvent('ad_iframe_click_leave', 'click', {
							'data-ad-slot': this.activeIframe.adSlot,
						})
					}
				}
			}

			document.addEventListener('visibilitychange', this.visibilityChangeHandler)
		},
		trackEvent(eventName, eventValue, dataAttributes) {
			// 事件追踪逻辑
			console.log(`${eventName} with value ${eventValue} on`, dataAttributes)

			// 调用核心功能
			if (typeof this.$eventrack === 'function') {
				this.$eventrack(eventName, eventValue, {
					...dataAttributes,
				})
			}
		},
		//客户端交互end
		getBlankImg() {
			let randomNum = Math.floor(Math.random() * 9) + 1
			// console.log(require(`@/assets/i${randomNum}.webp`));
			return require(`@/assets/i${randomNum}.webp`)
		},
	},
}
</script>
