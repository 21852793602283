<template>
	<CommonPage
		:wrapper="true"
		backTitle="Privacy policy"
		:back="true">
		<div class="def">
			<h2>Introduction</h2>
			<p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information.</p>

			<h2>Information Collection</h2>
			<ul>
				<li>
					<strong>Personal Information:</strong>
					We collect personal information when you register, subscribe to our newsletter, or interact with the site.
				</li>
				<li>
					<strong>Cookies:</strong>
					We use cookies and similar technologies to enhance user experience and analyze site traffic.
				</li>
			</ul>

			<h2>Use of Information</h2>
			<ul>
				<li>
					<strong>Purpose:</strong>
					We use your information to provide and improve our services, communicate with you, and personalize your experience.
				</li>
				<li>
					<strong>Sharing:</strong>
					We do not sell or rent your personal information to third parties. We may share information with service providers who assist us in operating the site.
				</li>
			</ul>

			<h2>Data Security</h2>
			<p>We implement industry-standard security measures to protect your information from unauthorized access, disclosure, or misuse.</p>

			<h2>User Rights</h2>
			<ul>
				<li>
					<strong>Access:</strong>
					You have the right to access your personal information and request corrections or deletions.
				</li>
				<li>
					<strong>Opt-Out:</strong>
					You can opt-out of receiving marketing communications at any time.
				</li>
			</ul>

			<h2>Changes to Policy</h2>
			<p>We may update this policy periodically. Please review it regularly for any changes. Your continued use of the site constitutes acceptance of any changes.</p>
		</div>
	</CommonPage>
</template>

<script>
export default {
	name: 'Privacy',
	metaInfo () {
		return {
		title: this.title,
		meta: [
			{
				name: 'description',
				content: this.description,
			},
			{
				name: 'title',
				content: this.title,
			},
			{
				property: 'og:title',
				content: this.title,
			},
			{
				property: 'og:description',
				content: this.description,
			},
		],
	}
	},
	computed: {
		title () {
			return `Privacy policy | ${this.domainConfig['IAmTitle']}`
		},
		description () {
			return this.domainConfig.description
		}
	},
	mounted() {
		document.querySelector('body').style.backgroundColor = '#fcfbfb'
	}
}
</script>
